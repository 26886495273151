import React, { useState, useEffect } from 'react';
import useGlobal from '../store';
import ProductImg from './ProductImg';

const ProcessingItemsItem = ({ item }) => {
  const [globalState, globalActions] = useGlobal();
  const { lang, text, selectedItems, channels } = globalState;
  const [orderChannel, setOrderChannel] = useState('ALS');
  const [isRefundException] = useState(item.refund_exception);
  const [isAltidollarRefundCampaign] = useState(item.altidollar_refund);

  useEffect(() => {
    if (item.channel) {
      setOrderChannel(item.channel);
    }
  }, [item])
  async function validateItemOrder(e, item) {
    e.preventDefault();

    const orderNb = e.target.validate_item_order.value;
    if (orderNb.length > 0) {
      await globalActions.items.updateValidatedItem(item, orderNb, orderChannel);
    }

    const $validateInput = document.querySelector('[name="validate_item_order"]');
    if ($validateInput) {
      document.querySelector('[name="validate_item_order"]').value = '';
      document.querySelector('[name="validate_item_order"]').focus();
    }
  }

  function toggleAltidollarsRefund(e) {
    e.preventDefault();
    const selectedItem = selectedItems.find(el => el.uniq_id === item.uniq_id);
    const current = item.altidollar_refund;
    globalActions.items.updateItem(selectedItem, 'altidollar_refund', !current);
  }

  return (
    <div className="sku-container">
      <div className="sku-container__top">
        <ProductImg item={item} size="55px" margin="0 0.25em 0 0" />
        <div>
          <h4 className="sku-container__title">
            {item.sku} - {item.vendor} - {lang === 'fr' ? item.title.split('|-|')[1] : item.title.split('|-|')[0]}
          </h4>
          <>
            {item.validated ? (
              <>
                <p className="sku-container__order">
                  {text.return_processing.order}: {item.channel}-{item.order_name}
                </p>
              </>
            ) : (
                <form className="sku_form" onSubmit={e => validateItemOrder(e, item)}>
                  <input
                    className="validate-item-order__input"
                    name="validate_item_order"
                    placeholder={text.return_processing.order_input_placeholder}
                    autoComplete="off"
                    autoFocus
                  />
                  <select onChange={e => setOrderChannel(e.target.value)} className="validate-item-order__select">
                    {Object.keys(channels).map(name => (
                      <option key={channels[name].channel_id} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>

                  <button>{text.return_processing.validate}</button>
                </form>
              )}
          </>
        </div>
      </div>

      <div className="actions">
        <div className="processing__sku__checkboxes">

          {item.sku.indexOf('MK') > -1 && (
            <p>
              <strong>{text.return_processing.no_refund_mp}</strong>
            </p>
          )}

          {!item.isInstalmentsGateway && channels[orderChannel].store_credits_policy && (
            <label className="processing__sku__altidollar-refund checkbox-container" onClick={toggleAltidollarsRefund}>
              <input name="altidollar-refund" type="checkbox" checked={item.altidollar_refund} readOnly />
              <span className="checkmark" />
              <span>
                {text.return_processing.altidollar_refund}
                {isAltidollarRefundCampaign && <b>&nbsp;(CX)</b>}
              </span>
            </label>
          )}
        </div>

        <button
          onClick={() => {
            globalActions.items.removeSelectedItem(item);
            if (document.querySelector('[name="skuOrUPC"]')) {
              document.querySelector('[name="skuOrUPC"]').focus();
            }
          }}
        >
          {text.return_processing.remove}
        </button>
      </div>
    </div>
  );
};

export default ProcessingItemsItem;
